.Button_btn__LpqLK {
    --arrow-height: calc(1em * 0.8);
    --arrow-width: calc(1em * 15 / 13 * 0.8);
    font-family: var(--rubik);
    display: inline-block;
    box-sizing: border-box;
    border: 2px solid #e3d500;
    border-radius: 16px;
    padding: 14px 20px;
    line-height: 1;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    background: #fbe30c;
    color: #191b19;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    cursor: pointer;
}
    .Button_btn__LpqLK.Button_arrow__WYnjb > span {
        padding-right: calc(0.3em + var(--arrow-width));
    }
    .Button_btn__LpqLK:focus:not(:focus-visible) {
        outline: none;
    }
    .Button_btn__LpqLK.Button_arrow__WYnjb::after {
        content: '';
        display: inline-block;
        width: var(--arrow-width);
        height: var(--arrow-height);
        margin-left: calc(-1 * var(--arrow-width));
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.581" viewBox="0 0 24 20.581"><path id="arrow-right" d="M23.5,75.426A1.717,1.717,0,0,0,23.5,73l-8.572-8.572A1.716,1.716,0,0,0,12.5,66.854l3.106,3.1L18.151,72.5H1.714a1.714,1.714,0,0,0,0,3.429H18.146L12.5,81.576A1.716,1.716,0,0,0,14.931,84L23.5,75.431Z" transform="translate(0 -63.925)" fill="%23191b19"/></svg>');
        vertical-align: middle;
        transform: translateY(-10%);
    }
    .Button_btn__LpqLK:hover,
    .Button_btn__LpqLK:active,
    .Button_btn__LpqLK:focus {
        background-color: #38a2ff;
        border-color: #2c85d5;
        color: #fff;
    }
    .Button_btn__LpqLK:hover.Button_arrow__WYnjb::after, .Button_btn__LpqLK:active.Button_arrow__WYnjb::after, .Button_btn__LpqLK:focus.Button_arrow__WYnjb::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.581" viewBox="0 0 24 20.581"><path id="arrow-right" d="M23.5,75.426A1.717,1.717,0,0,0,23.5,73l-8.572-8.572A1.716,1.716,0,0,0,12.5,66.854l3.106,3.1L18.151,72.5H1.714a1.714,1.714,0,0,0,0,3.429H18.146L12.5,81.576A1.716,1.716,0,0,0,14.931,84L23.5,75.431Z" transform="translate(0 -63.925)" fill="%23ffffff"/></svg>');
        }
.Button_large__YDf8n {
    padding: 17px 31px;
    border-radius: 24px;
    font-size: 18px;
    border-width: 3px;
}

.Button_small__3bv9v {
    padding: 12px 14px;
    font-size: 14px;
    border-radius: 12px;
    border-width: 2px;
}

.Button_black__lf5bQ {
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.Button_black__lf5bQ.Button_arrow__WYnjb::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_black__lf5bQ:hover,
    .Button_black__lf5bQ:active,
    .Button_black__lf5bQ:focus {
        background-color: #38a2ff;
        border-color: #2c85d5;
        color: #fff;
    }

.Button_orange__2u_12 {
    background: #fd733c;
    border-color: #e2612b;
    color: #fff;
}

.Button_orange__2u_12.Button_arrow__WYnjb::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_orange__2u_12:hover {
        border-color: #de4d14;
        background-color: #ff5b1a;
    }
.Button_max-orange__aeI2E {
    background: var(--primary-max-brand-color-300, #fc5b1a);
    border-color: var(--primary-max-brand-color-400, #de4d14);
    color: #fff;
}
.Button_max-orange__aeI2E.Button_arrow__WYnjb::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }
.Button_max-orange__aeI2E:hover,
    .Button_max-orange__aeI2E:active,
    .Button_max-orange__aeI2E:focus {
        border-color: var(--primary-max-brand-color-400, #de4d14);
        background-color: var(--primary-max-brand-color-400, #de4d14);
    }

.Button_max-black__J3Xt1 {
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.Button_max-black__J3Xt1.Button_arrow__WYnjb::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_max-black__J3Xt1:hover,
    .Button_max-black__J3Xt1:active,
    .Button_max-black__J3Xt1:focus {
        border-color: var(--primary-max-brand-color-400, #de4d14);
        background-color: var(--primary-max-brand-color-400, #de4d14);
        color: #fff;
    }

.Button_black-white__3uBEk {
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.Button_black-white__3uBEk.Button_arrow__WYnjb {
        text-wrap: nowrap;
    }

.Button_black-white__3uBEk.Button_arrow__WYnjb::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
        }

.Button_black-white__3uBEk:hover,
    .Button_black-white__3uBEk:active,
    .Button_black-white__3uBEk:focus {
        border-color: #000;
        background-color: #fff;
        color: #000;
    }

.Button_black-white__3uBEk:hover.Button_arrow__WYnjb::after, .Button_black-white__3uBEk:active.Button_arrow__WYnjb::after, .Button_black-white__3uBEk:focus.Button_arrow__WYnjb::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23000"/></svg>');
        }

.Button_blue__R7zO8 {
    background-color: #38a2ff;
    color: #fff;
    border-color: #3393f2;
}

.Button_blue__R7zO8.Button_arrow__WYnjb::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_blue__R7zO8:hover,
    .Button_blue__R7zO8:active,
    .Button_blue__R7zO8:focus {
        background-color: #38a2ff;
        border-color: #2c85d5;
        color: #fff;
    }

.Button_blue-yellow__rHe_Q {
    background-color: #38a2ff;
    color: #fff;
    border-color: #3393f2;
}

.Button_blue-yellow__rHe_Q.Button_arrow__WYnjb::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_blue-yellow__rHe_Q:hover,
    .Button_blue-yellow__rHe_Q:active,
    .Button_blue-yellow__rHe_Q:focus {
        background-color: #fbe30c;
        border-color: #e3d500;
        color: #191b19;
    }

.Button_blue-yellow__rHe_Q:hover.Button_arrow__WYnjb::after, .Button_blue-yellow__rHe_Q:active.Button_arrow__WYnjb::after, .Button_blue-yellow__rHe_Q:focus.Button_arrow__WYnjb::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23191b19"/></svg>');
        }

.Button_ghost__gUr9R {
    border-color: #abbcc9;
    background: transparent;
    color: #000;
}

.Button_ghost__gUr9R:hover,
    .Button_ghost__gUr9R:active,
    .Button_ghost__gUr9R:focus {
        background-color: #000;
        border-color: #000;
        color: #fff;
    }

.Button_ghost-blue__1GUEQ {
    border-color: #19a1ff;
    background: transparent;
    color: #fff;
    transition: all 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.Button_ghost-blue__1GUEQ.Button_arrow__WYnjb::after {
        transition: all 0.3s ease-in-out, border-color 0.3s ease-in-out;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }

.Button_ghost-blue__1GUEQ:hover,
    .Button_ghost-blue__1GUEQ:active,
    .Button_ghost-blue__1GUEQ:focus {
        background-color: transparent;
        border-color: var(--primary-max-brand-color-300, #fc5b1a);
        color: var(--primary-max-brand-color-300, #fc5b1a);
    }

.Button_ghost-blue__1GUEQ:hover.Button_arrow__WYnjb::after, .Button_ghost-blue__1GUEQ:active.Button_arrow__WYnjb::after, .Button_ghost-blue__1GUEQ:focus.Button_arrow__WYnjb::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fc5b1a"/></svg>');
        }
.Button_icon__7soQQ {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.3em;
    width: 1em;
    height: 1em;
    background: currentColor;
    -webkit-mask-image: var(--icon-src);
            mask-image: var(--icon-src);
    -webkit-mask-size: contain;
            mask-size: contain;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
}

/*------------------------------------*\
    ACADEMY BANNER
\*------------------------------------*/

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 {
        position: relative;
        padding: 0;
    }

@media only screen and (min-width: 600px) {

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 {
            padding: 60px
    }
        }

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 svg {
            position: absolute;
            filter: drop-shadow(8px 8px 41px rgba(0, 0, 0, 0.3));
        }

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 svg:nth-of-type(1) {
                display: none;
            }

@media only screen and (min-width: 600px) {

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 svg:nth-of-type(1) {
                    display: block;
                    top: 7%;
                    left: 3%;
                    width: 92px;
                    height: 92px
            }
                }

@media only screen and (min-width: 980px) {

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 svg:nth-of-type(1) {
                    top: 11%;
                    left: 10%
            }
                }

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 svg:nth-of-type(2) {
                display: none;
            }

@media only screen and (min-width: 980px) {

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 svg:nth-of-type(2) {
                    display: block;
                    width: 72px;
                    height: 72px;
                    top: 41%;
                    left: 3%
            }
                }

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 svg:nth-of-type(3) {
                display: none;
            }

@media only screen and (min-width: 600px) {

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 svg:nth-of-type(3) {
                    display: block;
                    bottom: 10%;
                    left: 17%;
                    width: 52px;
                    height: 52px
            }
                }

@media only screen and (min-width: 980px) {

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 svg:nth-of-type(3) {
                    top: 59%;
                    left: 17%
            }
                }

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 svg:nth-of-type(4) {
                display: none;
            }

@media only screen and (min-width: 600px) {

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 svg:nth-of-type(4) {
                    display: block;
                    bottom: 40%;
                    right: 28%;
                    width: 42px;
                    height: 42px
            }
                }

@media only screen and (min-width: 980px) {

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 svg:nth-of-type(4) {
                    top: 27%;
                    right: 13%
            }
                }

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 svg:nth-of-type(5) {
                display: none;
            }

@media only screen and (min-width: 600px) {

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 svg:nth-of-type(5) {
                    display: block;
                    top: 10%;
                    right: 17%;
                    width: 52px;
                    height: 52px
            }
                }

@media only screen and (min-width: 980px) {

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 svg:nth-of-type(5) {
                    width: 72px;
                    height: 72px;
                    top: 69%;
                    right: 21%
            }
                }

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 svg:nth-of-type(6) {
                display: none;
            }

@media only screen and (min-width: 600px) {

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 svg:nth-of-type(6) {
                    display: block;
                    width: 72px;
                    height: 72px;
                    top: 62%;
                    right: 2%
            }
                }

@media only screen and (min-width: 980px) {

.CertificationBanner_certification__GJau5 .CertificationBanner_outer__2lh55 svg:nth-of-type(6) {
                    width: 92px;
                    height: 92px;
                    top: 46%;
                    right: 2%
            }
                }

.CertificationBanner_certification__GJau5 .CertificationBanner_inner__av85t {
        padding: 20px;
        border-radius: 12px;
        border: 2px solid #d3d5d9;
        background-image: url(https://us-wn-g.gr-cdn.com/_next/static/media/gradient.58b73abd.avif);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-origin: padding-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        justify-items: center;
    }

@media only screen and (min-width: 600px) {

.CertificationBanner_certification__GJau5 .CertificationBanner_inner__av85t {
            padding: 40px 20px
    }
        }

@media only screen and (min-width: 980px) {

.CertificationBanner_certification__GJau5 .CertificationBanner_inner__av85t {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr auto
    }
        }

.CertificationBanner_certification__GJau5 .CertificationBanner_inner__av85t h5 {
            margin: 0 0 23px;
            padding: 0;
            font-family: var(--rubik);
            font-weight: 600;
            font-size: 34px;
            line-height: 1.3;
            text-align: center;
        }

.CertificationBanner_certification__GJau5 .CertificationBanner_inner__av85t h5 span {
                color: var(--primary-brand-color-600);
            }

.CertificationBanner_certification__GJau5 .CertificationBanner_inner__av85t .CertificationBanner_button__zip4O {
            white-space: nowrap;
        }

.MarketingLinks_news__gJqiO {
    --roboto: 'RobotoFlex', sans-serif;
    padding: 60px 0 30px;
}
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
    }
    @media only screen and (max-width: 1279px) {
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto
    }
        }
    @media only screen and (max-width: 979px) {
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 {
            grid-template-columns: 1fr;
            grid-template-rows: auto
    }
        }
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 span {
            font-family: var(--roboto);
            font-weight: 400;
            font-size: 18px;
            line-height: 1.2;
            letter-spacing: 0.5px;
            display: block;
            margin-bottom: 1.25em;
        }
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 span strong {
                font-weight: 500;
            }
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 ul {
            padding: 0;
            list-style: none;
        }
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 ul li {
                font-family: var(--roboto);
                font-weight: 500;
                font-size: 14px;
                color: #09222b;
                line-height: 1.5;
                margin-bottom: 25px;
            }
    @media only screen and (max-width: 1279px) {
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 ul li {
                    margin-bottom: 15px
            }
                }
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 ul li a {
                    color: #09222b;
                    text-decoration: none;
                }
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 ul li a:hover {
                        text-decoration: underline;
                    }
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 .MarketingLinks_articles__Help_ {
            padding: 0 70px 0 15px;
        }
    @media only screen and (max-width: 1279px) {
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 .MarketingLinks_articles__Help_ {
                margin-top: 20px;
                padding: 0 10px
        }
                .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 .MarketingLinks_articles__Help_:first-child {
                    margin-top: 0;
                }
            }
    @media only screen and (max-width: 979px) {
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 .MarketingLinks_articles__Help_ {
                padding: 0
        }
            }
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 .MarketingLinks_promo__LlLf_ {
            padding: 0 15px;
            text-align: center;
        }
    @media only screen and (max-width: 1279px) {
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 .MarketingLinks_promo__LlLf_ {
                margin-top: 20px;
                padding: 0 10px
        }
                .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 .MarketingLinks_promo__LlLf_:first-child {
                    margin-top: 0;
                }
            }
    @media only screen and (max-width: 979px) {
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 .MarketingLinks_promo__LlLf_ {
                padding: 0
        }
            }
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 .MarketingLinks_promo__LlLf_ figure img {
                    box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.05);
                }
    @media only screen and (max-width: 1279px) {
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 .MarketingLinks_promo__LlLf_ {
                grid-column: 1;
                grid-row: 1 / span 2;
                align-self: center
        }
            }
    @media only screen and (max-width: 979px) {
    .MarketingLinks_news__gJqiO .MarketingLinks_inner__hqN26 .MarketingLinks_promo__LlLf_ {
                grid-row: auto
        }
            }

.NewsletterForm_newsletter__eSKRQ {
    --roboto: 'RobotoFlex', sans-serif;
    --blue: #00baff;
    --placeholder: #8a9198;
    --form-error-text-color: #e22000;
    --form-valid-text-color: #47d800;
    background: #f1f1f1;
    padding: 40px 0;
    font-family: var(--roboto);
    position: relative;
    z-index: 1;
}
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_container__AnhTd {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 150px;
    }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_thankYou__YzvUO {
        display: flex;
        justify-content: center;
        padding: 37px 0;
    }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_thankYou__YzvUO svg {
            flex: 0 0 auto;
            display: inline-block;
            stroke: #00cb6b;
            width: 30px;
            height: 30px;
            margin-right: 15px;
        }
    @media screen and (min-width: 980px) {
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_thankYou__YzvUO svg {
                flex-shrink: 1
        }
            }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_thankYou__YzvUO h2 {
            margin: 0;
            padding: 0;
            font-weight: 400;
            font-size: 32px;
            line-height: 1.2;
            letter-spacing: 0.015em;
        }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_thankYou__YzvUO h2 strong {
                font-weight: 700;
            }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_form___oOHZ > span {
            font-size: 26px;
            line-height: 1.2;
            letter-spacing: 0.5px;
            text-align: center;
            display: block;
            margin-bottom: 1.25em;
        }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_form___oOHZ > span strong {
                font-weight: 500;
            }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_form___oOHZ .NewsletterForm_mainErrors__XawNi {
            color: var(--form-error-text-color);
            text-align: center;
            margin-bottom: 50px;
        }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_form___oOHZ form {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND {
        position: relative;
        font-size: 14px;
        margin: 0 10px 56px;
        width: 280px;
    }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND input[type='text'],
        .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND input[type='email'] {
            background: #fff;
            border: 1px solid #e2e2e2;
            padding: 0.9em;
            border-radius: 5px;
            width: 100%;
            line-height: 1.8;
            box-sizing: border-box;
        }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND input[type='text']:focus:not(:focus-visible), .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND input[type='email']:focus:not(:focus-visible) {
                outline: none;
            }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND input[type='text']:focus, .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND input[type='email']:focus {
                border-color: var(--primary-brand-color-400);
            }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND input[type='text']::-moz-placeholder, .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND input[type='email']::-moz-placeholder {
                color: var(--placeholder);
            }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND input[type='text']::placeholder, .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND input[type='email']::placeholder {
                color: var(--placeholder);
            }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND label {
            position: absolute;
            top: 0;
            left: 0.8em;
            pointer-events: none;
            padding: 0.9em 0;
            box-sizing: border-box;
            transition: transform 0.1s linear;
            line-height: 1;
            font-size: 18px;
            color: #677689;
            letter-spacing: 0.01em;
        }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND :focus + label,
        .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND .NewsletterForm_notEmpty__kSdH7 + label {
            transform: translateY(-1.7em) scale(0.83);
            transform-origin: top left;
            border-radius: 5px;
            color: #000;
        }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND .NewsletterForm_formControl__oNrQn {
            max-width: none;
            font-size: 18px;
            height: 2.8em;
            padding: 0.9em 0.8em;
        }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND.NewsletterForm_hasError__NBYln input[type='text'],
            .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND.NewsletterForm_hasError__NBYln input[type='email'] {
                border: 1px solid var(--form-error-text-color);
            }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND.NewsletterForm_hasError__NBYln input[type='text']:focus ~ label, .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND.NewsletterForm_hasError__NBYln input[type='text'].NewsletterForm_notEmpty__kSdH7 ~ label, .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND.NewsletterForm_hasError__NBYln input[type='text'][value]:not([value='']):not(.NewsletterForm_empty__PoPso) ~ label, .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND.NewsletterForm_hasError__NBYln input[type='email']:focus ~ label, .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND.NewsletterForm_hasError__NBYln input[type='email'].NewsletterForm_notEmpty__kSdH7 ~ label, .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND.NewsletterForm_hasError__NBYln input[type='email'][value]:not([value='']):not(.NewsletterForm_empty__PoPso) ~ label {
                        color: var(--form-error-text-color);
                    }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND.NewsletterForm_hasError__NBYln .NewsletterForm_checkbox__VZZn_ input ~ span {
                color: var(--form-error-text-color);
            }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND.NewsletterForm_hasSuccess__X4j4Z input[type='text'],
            .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND.NewsletterForm_hasSuccess__X4j4Z input[type='email'] {
                border: 1px solid var(--form-valid-text-color);
            }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND.NewsletterForm_hasSuccess__X4j4Z input[type='text']:focus ~ label, .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND.NewsletterForm_hasSuccess__X4j4Z input[type='text'].NewsletterForm_notEmpty__kSdH7 ~ label, .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND.NewsletterForm_hasSuccess__X4j4Z input[type='text'][value]:not([value='']):not(.NewsletterForm_empty__PoPso) ~ label, .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND.NewsletterForm_hasSuccess__X4j4Z input[type='email']:focus ~ label, .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND.NewsletterForm_hasSuccess__X4j4Z input[type='email'].NewsletterForm_notEmpty__kSdH7 ~ label, .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND.NewsletterForm_hasSuccess__X4j4Z input[type='email'][value]:not([value='']):not(.NewsletterForm_empty__PoPso) ~ label {
                        color: var(--form-valid-text-color);
                    }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND.NewsletterForm_hasSuccess__X4j4Z .NewsletterForm_checkbox__VZZn_ input ~ span {
                color: var(--form-valid-text-color);
            }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroup__4xPND .NewsletterForm_error__Rooat {
            color: var(--form-error-text-color);
            font-size: 12px;
            letter-spacing: 0.05em;
            line-height: 1.4;
            position: absolute;
            bottom: -20px;
        }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroupCheckbox__DtUDu {
        order: 1;
        width: 100%;
        margin-bottom: 27px;
        padding: 0 20px;
    }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroupCheckbox__DtUDu .NewsletterForm_checkbox__VZZn_ {
            max-width: 527px;
            margin: 0 auto;
        }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroupCheckbox__DtUDu .NewsletterForm_checkbox__VZZn_ input[type='checkbox'] {
                visibility: hidden;
                position: absolute;
            }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroupCheckbox__DtUDu .NewsletterForm_checkbox__VZZn_ input[type='checkbox'] + .NewsletterForm_customCheckbox__eEm1B {
                display: block;
                position: absolute;
                width: 17px;
                height: 17px;
                border: 1px solid #e2e2e2;
                border-radius: 5px;
                box-sizing: border-box;
                top: 0;
                transform: translateX(-100%) translateX(-10px);
                background: #fff;
            }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroupCheckbox__DtUDu .NewsletterForm_checkbox__VZZn_ input[type='checkbox']:checked + .NewsletterForm_customCheckbox__eEm1B {
                background: #fff url(https://us-wn-g.gr-cdn.com/_next/static/media/check-blue.c6522f1d.svg) no-repeat center;
                background-size: 11px auto;
            }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroupCheckbox__DtUDu .NewsletterForm_checkbox__VZZn_ label {
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: center;
                top: 0;
                left: 0;
                pointer-events: all;
                padding: 0;
            }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroupCheckbox__DtUDu .NewsletterForm_checkbox__VZZn_ label p {
                    font-size: 11px;
                    line-height: 1.3;
                    color: #000;
                    margin-top: 0;
                    margin-bottom: 0.6em;
                    letter-spacing: 0;
                }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_formGroupCheckbox__DtUDu .NewsletterForm_checkbox__VZZn_ label p a {
                        color: var(--primary-brand-color-800);
                    }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_buttonGroup__hlvhr {
        order: 1;
        width: 205px;
        margin-bottom: 28px;
    }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_buttonGroup__hlvhr .NewsletterForm_btn__oQDCl {
            cursor: pointer;
            margin: auto;
            width: 100%;
            padding: 14px 30px;
            border-radius: 50px;
            border: 0;
            box-sizing: border-box;
            font-weight: 400;
            font-size: 16px;
            font-family: var(--roboto);
            letter-spacing: 1px;
            color: #fff;
            border-color: var(--primary-btn-bg-color);
            background: var(--primary-btn-bg-color);
            transition: background 0.2s ease-in-out;
        }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_buttonGroup__hlvhr .NewsletterForm_btn__oQDCl:focus:not(:focus-visible) {
                outline: none;
            }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_buttonGroup__hlvhr .NewsletterForm_btn__oQDCl:hover,
            .NewsletterForm_newsletter__eSKRQ .NewsletterForm_buttonGroup__hlvhr .NewsletterForm_btn__oQDCl:focus {
                border-color: var(--primary-btn-hover-bg-color);
                background: var(--primary-btn-hover-bg-color);
            }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_buttonGroup__hlvhr .NewsletterForm_btn__oQDCl:hover:focus:not(:focus-visible), .NewsletterForm_newsletter__eSKRQ .NewsletterForm_buttonGroup__hlvhr .NewsletterForm_btn__oQDCl:focus:focus:not(:focus-visible) {
                    outline: none;
                }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_buttonGroup__hlvhr .NewsletterForm_btn__oQDCl:disabled {
                opacity: 0.5;
                pointer-events: none;
            }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_terms__s_gKv {
        width: 100%;
        text-align: center;
        padding-top: 6px;
        order: 2;
    }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_terms__s_gKv small {
            font-size: 10px;
            color: #6e6e6e;
        }
    .NewsletterForm_newsletter__eSKRQ .NewsletterForm_terms__s_gKv small a {
                color: #6e6e6e;
                text-decoration: underline;
            }

.Pagination_pagination__C5FI_ {
    container-name: pagination;
    container-type: inline-size;
}
    .Pagination_pagination__C5FI_ ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        list-style: none;
        padding: 0;
        justify-content: space-between;
    }
    @container (min-width: 680px) {
    .Pagination_pagination__C5FI_ ul {
            justify-content: flex-start
    }
        }
    .Pagination_pagination__C5FI_ li {
        margin: 0 3px;
    }
.Pagination_element__kdX22 > * {
        text-decoration: none;
        color: inherit;
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        min-width: 32px;
        height: 32px;
        padding: 0 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    }
.Pagination_element__kdX22 > a:hover,
    .Pagination_element__kdX22 > a:active {
        border-color: #bbb;
    }
.Pagination_element__kdX22.Pagination_dots__cIdab > * {
        border: 0;
        padding-left: 2px;
        padding-right: 2px;
        min-width: 32px;
        font-size: 19px;
        font-weight: 400;
    }
.Pagination_element__kdX22.Pagination_current__XdcWN > * {
        color: var(--primary-link-color);
        border-color: currentColor;
    }
.Pagination_element__kdX22.Pagination_prev__GquZt > * {
        margin-right: 10px;
    }
.Pagination_element__kdX22.Pagination_next__TosN_ > * {
        margin-left: 10px;
    }
.Pagination_element__kdX22.Pagination_prev__GquZt > *::before,
    .Pagination_element__kdX22.Pagination_next__TosN_ > *::before {
        content: '';
        display: inline-block;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
    }
.Pagination_element__kdX22.Pagination_prev__GquZt > *::before {
        border-right: 5px solid #576a7b;
    }
.Pagination_element__kdX22.Pagination_next__TosN_ > *::before {
        border-left: 5px solid #576a7b;
    }
.Pagination_element__kdX22.Pagination_isDisabled__H2e9Y > * {
        pointer-events: none;
        opacity: 0.5;
    }

/* If pagination has "..." */
/* This selector SHOULD be sufficient but due to a bug in production build process (minification?) I had to wrap `:nth-last-child(2)` with `:is(:nth-last-child(2))` */
/* :where(.element:not(.prev):not(.next):not(.current):not(.dots):not(:nth-child(2)):not(:nth-last-child(2)):not(.current + *):not(:has(+ .current))) { */
.Pagination_pagination__C5FI_:has(.Pagination_dots__cIdab) :where(.Pagination_element__kdX22:not(.Pagination_prev__GquZt):not(.Pagination_next__TosN_):not(.Pagination_current__XdcWN):not(.Pagination_dots__cIdab):not(:nth-child(2)):not(:is(:nth-last-child(2))):not(.Pagination_current__XdcWN + *):not(:has(+ .Pagination_current__XdcWN))) {
        display: none;
    }

/* Show all links BEFORE and one after selected one if only "end" dots shown  */
.Pagination_pagination__C5FI_:has(.Pagination_dots__cIdab:nth-last-child(3)):not(:has(.Pagination_dots__cIdab:nth-child(3))) :where(.Pagination_element__kdX22:has(~ .Pagination_current__XdcWN)) {
        display: initial;
    }
.Pagination_pagination__C5FI_:has(.Pagination_dots__cIdab:nth-last-child(3)):not(:has(.Pagination_dots__cIdab:nth-child(3))) :where(.Pagination_current__XdcWN + .Pagination_element__kdX22) {
        display: initial;
    }

/* Show all links AFTER and one before selected one if only "end" dots shown  */
.Pagination_pagination__C5FI_:has(.Pagination_dots__cIdab:nth-child(3)):not(:has(.Pagination_dots__cIdab:nth-last-child(3))) :where(.Pagination_current__XdcWN ~ .Pagination_element__kdX22) {
        display: initial;
    }
.Pagination_pagination__C5FI_:has(.Pagination_dots__cIdab:nth-child(3)):not(:has(.Pagination_dots__cIdab:nth-last-child(3))) :where(.Pagination_element__kdX22:has(+ .Pagination_current__XdcWN)) {
        display: initial;
    }

@container (min-width: 680px) {

.Pagination_pagination__C5FI_ .Pagination_element__kdX22 {
        display: initial
}
    }

.Pagination_pagination__7BuP5 {
    font-family: var(--roboto);
}
.Pagination_element__Qs60O > * {
    border: 2px solid #e5e5e5;
    border-radius: 4px;
    transition: border-color 0.2s ease-out;
}

.Search_input__pBymv {
    background: #fff;
    border: 1px solid #bcbcbc;
    border-radius: 30px 0 0 30px;
    border-right: 0;
    padding: 10px 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-size: 16px;
}
    .Search_input__pBymv::-moz-placeholder {
        color: #8a9198;
        font-size: 14px;
    }
    .Search_input__pBymv::placeholder {
        color: #8a9198;
        font-size: 14px;
    }
    .Search_input__pBymv:focus-visible {
        outline: none;
    }
    .Search_input__pBymv:focus {
        outline: none;
    }
.Search_button__i_RgL {
    background: #fff;
    border: 1px solid #bcbcbc;
    border-left: 0;
    white-space: nowrap;
    border-radius: 0 30px 30px 0;
    padding: 10px;
    cursor: pointer;
    margin: 0;
}
.Search_button__i_RgL svg {
        stroke: #bcbcbc;
        stroke-width: 2px;
        width: 15px;
        height: 15px;
        transition: stroke 0.2s ease-in-out;
    }
.Search_button__i_RgL:hover svg {
            stroke: #000;
        }
.Search_form-group__hTF0i {
    display: grid;
    grid-template-columns: 1fr 60px;
    grid-template-rows: 1fr;
    position: relative;
    max-width: 495px;
    height: 60px;
    margin: 0 auto;
}
.Search_form-group__hTF0i:focus-within > div input,
    .Search_form-group__hTF0i:focus-visible > div input,
    .Search_form-group__hTF0i:focus-within > button {
        border-color: #00baff;
    }
@supports (--css: variables) {
.Search_form-group__hTF0i:focus-within > div input,
    .Search_form-group__hTF0i:focus-visible > div input,
    .Search_form-group__hTF0i:focus-within > button {
            border-color: var(--primary-brand-color-400)
    }
        }

.Search_menu__DD3w9 {
    position: absolute;
    margin: 0;
    top: 100%;
    left: 50%;
    padding: 0;
    transform: translate(-50%, 7px);
    width: 100%;
    max-width: 433px;
    text-align: left;
    cursor: default;
    border: 1px solid #00baff;
    border-radius: 7px;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
    max-height: 254px;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    font-weight: 300;
    font-size: 17px;
    line-height: 2;
    z-index: 1;
}

@supports (--css: variables) {

.Search_menu__DD3w9 {
        border-color: var(--primary-brand-color-400)
}
    }

.Search_menu__DD3w9 li {
        position: relative;
        padding: 10px 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: var(--roboto);
        font-weight: 400;
        font-size: 12px;
        color: #7d8f9f;
        cursor: pointer;
    }

.Search_menu__DD3w9 li em {
            font-weight: 700;
            font-style: normal;
        }

.Search_menu__DD3w9 li:hover {
            background: #fafafa;
            color: #000;
        }

.FiltersDivider_line__u0jg9 {
    border: none;
    margin: 0;
    border-bottom: 1px solid #e6e6e6;
}

.ListHeader_manager__16EBB {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    font-family: 'RobotoFlex', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: $color-nav;
}
    @media screen and (max-width: 979px) {.ListHeader_manager__16EBB {
        top: 88px
}
    }
.ListHeader_filters__YA5qt {
    display: flex;
    gap: 20px;
    flex-direction: column;
}
@media screen and (min-width: 768px) {
.ListHeader_filters__YA5qt {
        flex-direction: row
}
    }
.ListHeader_filtersLabel___teqi {
    flex-shrink: 0;
    margin-top: 0.98em;
}
.ListHeader_filtersLabel___teqi:only-child {
        margin-top: 0;
    }
.ListHeader_view__ZvR52 {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
    margin-left: auto;
    text-align: right;
    margin-top: 10px;
    margin-bottom: 10px;
}

.ListHeader_viewType__kQeyA {
    display: flex;
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;
    flex-direction: row;
}

@media screen and (max-width: 979px) {

.ListHeader_viewType__kQeyA {
        display: none
}
    }

.ListHeader_viewType__kQeyA li {
        display: flex;
        align-items: center;
        padding: 0 5px;
        box-sizing: border-box;
    }

.ListHeader_viewType__kQeyA li button {
            background: none;
            cursor: pointer;
            border: none;
            padding: 0;
            height: 15px;
            display: block;
        }

.ListHeader_viewType__kQeyA li button svg {
                fill: #dddfe2;
                transition: fill 0.2s ease-in-out;
                pointer-events: none;
            }

.ListHeader_viewType__kQeyA li.ListHeader_active__C10PF svg, .ListHeader_viewType__kQeyA li:hover svg {
                fill: #9ca7b1;
            }
.ListHeader_select__fULBo {
    border: none;
    cursor: pointer;
    --arrow-color: var(--primary-brand-color-400);
    color: var(--primary-brand-color-400);
    padding: 5px 25px 5px 10px;
    text-align: right;
    box-sizing: border-box;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: #fff;
    background-image: linear-gradient(45deg, transparent 50%, var(--arrow-color) 50%),
        linear-gradient(135deg, var(--arrow-color) 50%, transparent 50%);
    background-position: calc(100% - 13px) calc(0.7em + 1px), calc(100% - 9px) calc(0.7em + 1px);
    background-size: 4px 4px, 4px 4px;
    background-repeat: no-repeat;
}
.ListHeader_select__fULBo:focus:not(:focus-visible) {
        outline: none;
    }

.ListHeader_bubble__Wt_kY {
    color: var(--primary-brand-color-400);
    border: 2px solid var(--primary-brand-color-400);
    border-radius: 4px;
    padding: 0.6em;
    margin: 0.4em 0.2em;
    display: inline-flex;
    align-items: center;
    gap: 7px;
    font-size: 14px;
    line-height: 0;
    font-weight: 400;
}

.ListHeader_bubble__Wt_kY button {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
    }

.ListHeader_bubble__Wt_kY img {
        display: block;
        width: 12px;
        height: 12px;
    }

.NoResults_noResults__4yHj_ {
    text-align: center;
    padding-bottom: 40px;
}
    .NoResults_noResults__4yHj_ h2 {
        font-weight: 500;
        font-size: 28px;
        color: #1f262f;
        margin-top: 1.3em;
    }
    .NoResults_noResults__4yHj_ p {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.4;
        font-style: italic;
        color: #6f6f6f;
    }
.NoResults_monster__JgfWt {
    width: 270px;
    height: 190px;
    margin-top: 40px;
}

.theme-high-contrast .ResourceCard_article__N764_ {
    --green: #008547;
    --green-hover: #1a915a;
    --list-description: #6e757c;
}

.ResourceCard_article__N764_ {
    --blue: #00baff;
    --blue-hover: #00a2ff;
    --green: #00cd6e;
    --green-hover: #1ad27d;
    --list-description: #8a9198;
    --roboto: 'RobotoFlex', sans-serif;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.05);
}

.ResourceCard_article__N764_ h2 {
        font-family: var(--roboto);
        letter-spacing: 0;
        font-weight: 700;
        font-size: 18px;
        color: #000;
        margin-top: auto;
        margin-bottom: auto;
    }

.ResourceCard_article__N764_ h2 a {
            color: #000;
            text-decoration: none;
        }

.ResourceCard_article__N764_ h2 a:hover {
                text-decoration: underline;
            }

.ResourceCard_article__N764_ h2 mark {
            background-color: #ffea00;
        }

.ResourceCard_article__N764_ figure {
        margin: 0;
    }

.ResourceCard_article__N764_ figure img {
            display: block;
            max-width: 123px;
        }

.ResourceCard_resources-list__dkN5P .ResourceCard_article__N764_ figure img {
                height: 182px;
                width: 123px;
            }

.ResourceCard_article__N764_ figure > a {
            display: block;
            text-align: center;
            text-decoration: none;
        }

.ResourceCard_article__N764_ figure > a .ResourceCard_inner__dPsfI {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

.ResourceCard_article__N764_ figure > a h3 {
                font-family: var(--roboto);
                font-weight: 400;
                color: #fff;
                font-size: 40px;
                margin: -20px 0 0;
            }

.ResourceCard_article__N764_ figure > a p {
                font-family: var(--roboto);
                font-weight: 500;
                font-size: 13px;
                color: #fff;
                margin: 0;
            }

.ResourceCard_article__N764_ figure > a svg {
                width: 35px;
                height: 35px;
                fill: #fff;
                stroke: #fff;
            }

.ResourceCard_article__N764_ figure > a.ResourceCard_green__L5fk5 {
                background: var(--green);
            }

.ResourceCard_article__N764_ figure > a.ResourceCard_blue__WQTGr {
                background: var(--blue);
            }

.ResourceCard_article__N764_.ResourceCard_videos__UaLWq figure a > *, .ResourceCard_article__N764_.ResourceCard_collections__ZSiRc figure a > *, .ResourceCard_article__N764_.ResourceCard_webinars__VVbC_ figure a > * {
            width: 123px;
            height: 182px;
            position: relative;
        }

.ResourceCard_article__N764_.ResourceCard_videos__UaLWq figure a > .ResourceCard_placeholder__RmpNg::after, .ResourceCard_article__N764_.ResourceCard_webinars__VVbC_ figure a > .ResourceCard_placeholder__RmpNg::after {
            content: '';
            width: 0;
            height: 0;
            border-top: 13px solid transparent;
            border-bottom: 13px solid transparent;
            border-left: 19px solid #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

.ResourceCard_article__N764_.ResourceCard_videos__UaLWq figure a > *, .ResourceCard_article__N764_.ResourceCard_webinars__VVbC_ figure a > * {
            background: var(--primary-brand-color-400);
        }

.ResourceCard_article__N764_.ResourceCard_videos__UaLWq figure:hover a > *, .ResourceCard_article__N764_.ResourceCard_webinars__VVbC_ figure:hover a > * {
            background: var(--primary-brand-color-300);
        }

.ResourceCard_article__N764_.ResourceCard_collections__ZSiRc figure a > * {
            background: var(--green);
        }

.ResourceCard_article__N764_.ResourceCard_collections__ZSiRc figure:hover a > * {
            background: var(--green-hover);
        }

.ResourceCard_article__N764_.ResourceCard_collections__ZSiRc .ResourceCard_count__A_cIU {
            color: #fff;
            font-size: 18px;
            margin-bottom: 0.2em;
        }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 10px 20px;
    }

@media screen and (max-width: 979px) {

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W {
            padding: 20px;
            justify-content: center
    }
        }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W h3 {
            font-family: var(--roboto);
            font-weight: 700;
            font-size: 18px;
            color: #000;
            margin-top: 10px;
            margin-bottom: 10px;
        }

@media screen and (max-width: 1279px) {

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W h3 {
                font-size: 16px
        }
            }

@media screen and (max-width: 979px) {

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W h3 {
                margin-top: 0
        }
            }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W h3 a {
                color: #000;
            }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W h3 a:hover {
                    text-decoration: underline;
                }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W p {
            font-family: var(--roboto);
            font-weight: 400;
            font-size: 12px;
            color: var(--list-description);
        }

[data-view='box'] .ResourceCard_article__N764_ .ResourceCard_content__ufs_W p {
                display: none;
            }

@media screen and (max-width: 979px) {

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W p {
                display: none
        }
            }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W ul {
            padding: 0;
            list-style: none;
        }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W ul li {
                font-family: var(--roboto);
                font-weight: 500;
                color: var(--blue);
            }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W ul li:first-child {
                    margin-left: 0;
                }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W ul a {
                text-decoration: none;
                color: var(--primary-link-color);
            }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W ul a:focus:not(:focus-visible) {
                    outline: none;
                }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W ul a:hover {
                    text-decoration: none;
                    color: var(--primary-link-hover-color);
                }

@media screen and (max-width: 979px) {

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W .ResourceCard_category__ypUIx {
                display: none
        }
            }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W .ResourceCard_category__ypUIx ul {
                display: flex;
            }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W .ResourceCard_category__ypUIx ul li {
                    padding: 0 15px;
                    font-size: 10px;
                    border-left: 1px solid #e8e8e8;
                }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W .ResourceCard_category__ypUIx ul li:first-child {
                        border: 0;
                        padding-left: 0;
                    }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W .ResourceCard_details__0RgGx {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W .ResourceCard_details__0RgGx ul {
                text-align: center;
            }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W .ResourceCard_details__0RgGx ul li {
                    display: inline-block;
                    padding: 8px 30px;
                    border-left: 1px solid #e8e8e8;
                    font-size: 16px;
                    color: #5f5f5f;
                }

@media screen and (max-width: 1279px) {

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W .ResourceCard_details__0RgGx ul li {
                        font-size: 14px
                }
                    }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W .ResourceCard_details__0RgGx ul li:first-child {
                        border: 0;
                    }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W .ResourceCard_details__0RgGx .ResourceCard_btn-wrap__dtshZ {
                flex-grow: 0;
            }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W .ResourceCard_details__0RgGx .ResourceCard_btn-wrap__dtshZ .ResourceCard_btn__hd__b {
                    font-weight: 400;
                    font-size: 16px;
                    padding: 16px 35px;
                }

@media screen and (max-width: 1279px) {

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W .ResourceCard_details__0RgGx .ResourceCard_btn-wrap__dtshZ .ResourceCard_btn__hd__b {
                        display: none
                }
                    }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W mark {
            background-color: #ffea00;
        }

.ResourceCard_article__N764_ .ResourceCard_content__ufs_W > :last-child {
            margin-bottom: 5px;
            margin-top: auto;
        }

.Checkbox_checkbox-wrapprer__sfk_G {
    margin-bottom: 14px;
}
.Checkbox_label__5BUdk {
    display: grid;
    grid-template-columns: 15px 1fr;
    grid-template-rows: auto;
}

.Checkbox_label__5BUdk > span {
    padding-inline-start: 8px;
    font-family: 'RobotoFlex', 'Helvetica', 'Arial', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #576a7b;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.Checkbox_checkbox__meyBI {
    background-color: #fff;
    position: relative;
    border-color: #fff;
    margin: 0;
    height: 13px;
    width: 13px;
    box-sizing: border-box;
    border-radius: 100px;
}

.Checkbox_checkbox__meyBI:focus-visible {
    outline-offset: 3px;
}

.Checkbox_checkbox__meyBI::after {
    visibility: visible;
    content: '';
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #e5e5e5;
    position: absolute;
    transition: border-color 0.6s ease-out;
    border-radius: 2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    margin: -2px;
}

.Checkbox_checkbox__meyBI:checked::after {
    background-color: var(--primary-brand-color-400);
    background-image: url(https://us-wn-g.gr-cdn.com/_next/static/media/check.eb6f5565.svg);
    background-repeat: no-repeat;
    background-size: 7px 5px;
    background-position: 50%;
    border-color: var(--primary-brand-color-400);
}

.Checkbox_checkbox__meyBI:checked ~ span {
    color: #1f262f;
}

.FieldGroup_group__v9kPM.FieldGroup_horizontal__bykOs {
    display: flex;
    flex-flow: row wrap;
    gap: 5px 20px;
}

.MultipleCheckboxes_fieldset__xz4G0 {
    border: none;
    padding: 0;
    margin: 0;
}

.MultipleCheckboxes_fieldset__xz4G0 legend {
    font-family: 'RobotoFlex', 'Helvetica', 'Arial', sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #000;
    cursor: pointer;
    padding: 14px 40px 14px 0;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.MultipleCheckboxes_fieldset__xz4G0 legend:hover {
    cursor: pointer;
}

.MultipleCheckboxes_fieldset__xz4G0 legend::before {
    background: url(https://us-wn-g.gr-cdn.com/_next/static/media/arrow.0949c90d.svg) no-repeat center center;
    content: '';
    display: inline-block;
    padding: 0;
    width: 14px;
    height: 6px;
    stroke-width: 1px;
    position: absolute;
    top: 50%;
    right: 13px;
    transform: translateY(-50%) rotate(0deg);
}
.MultipleCheckboxes_fieldset__xz4G0 .MultipleCheckboxes_open__z0hIi ~ div {
    margin-bottom: 14px;
}
.MultipleCheckboxes_fieldset__xz4G0 .MultipleCheckboxes_open__z0hIi::before {
    transform: translateY(-50%) rotate(180deg);
}

.ResourceList_section__clIaB {
    background: linear-gradient(180deg, #f1f1f1 0, #fff 250px);
    padding-top: 34px;
}
.ResourceList_main__lkaIC {
    display: grid;
    gap: 20px;
    grid-template-rows: auto auto 1fr auto;
    grid-template-columns: 1fr;
}
@media screen and (min-width: 768px) {
.ResourceList_main__lkaIC {
        grid-template-columns: 260px auto;
}
    }
.ResourceList_main__lkaIC {
    flex-direction: row-reverse;
}
.ResourceList_header__luxNS {
    grid-row: 1;
    grid-column: 1 / -1;
    margin-bottom: 25px;
}
@media screen and (min-width: 768px) {
.ResourceList_header__luxNS {
        margin-bottom: 65px
}
    }
.ResourceList_header__luxNS h1 {
        text-align: center;
        font-weight: 300;
        letter-spacing: -0.5px;
        margin-bottom: 20px;
        font-size: 26px;
        line-height: 1.2;
    }
@media screen and (min-width: 980px) {
.ResourceList_header__luxNS h1 {
            font-size: 32px
    }
        }
.ResourceList_header__luxNS h1 strong {
            font-weight: 400;
        }
.ResourceList_filters__zHL0a {
    grid-row: 2 / span 3;
    grid-column: 1;
    top: 88px;
    left: 0;
    align-self: start;
    z-index: 1070;
    position: fixed;
    height: calc(100vh - 88px);
    width: calc(100vw - 70px);
    transform: translateX(-100%);
    transition: transform 0.3s ease;
}
@media screen and (min-width: 767px) {
.ResourceList_filters__zHL0a {
        display: block;
        position: sticky;
        padding: 0;
        padding-bottom: 60px;
        margin-top: -20px;
        height: auto;
        width: auto;
        transform: none;
        z-index: 1
}
    }

.ResourceList_mobileFiltersShow__9rZGT {
    position: absolute;
    left: 100%;
    top: 49px;
    background: #fff;
    width: 45px;
    height: 68px;
    background-color: #fff;
    border-radius: 0 2px 2px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-left: 1px solid #e2e2e2;
    box-shadow: 1px 1px 5px 0 rgb(0 0 0 / 7%);
}

@media screen and (min-width: 767px) {

.ResourceList_mobileFiltersShow__9rZGT {
        display: none
}
    }

.ResourceList_mobileFiltersShow__9rZGT img {
        height: 9px;
        width: 15px;
        transform: rotate(-90deg);
        transition: transform 0.2s ease-out 0.2s;
    }
.ResourceList_mobileFiltersHide__5cheq {
    content: '';
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
    opacity: 0;
}
@media screen and (min-width: 767px) {
.ResourceList_mobileFiltersHide__5cheq {
        display: none
}
    }

.ResourceList_filtersVisible__SZmiI {
    transform: none;
}

.ResourceList_filtersVisible__SZmiI .ResourceList_mobileFiltersHide__5cheq {
        opacity: 1;
        transition: opacity 0.3s ease 0.3s;
    }

.ResourceList_filtersVisible__SZmiI .ResourceList_mobileFiltersShow__9rZGT img {
        transform: rotate(90deg);
    }

.ResourceList_filtersForm__SUACK {
    background: #fff;
    height: 100%;
    width: 100%;
    overflow: auto;
    overscroll-behavior-y: contain;
    padding: 10px 20px;
    box-sizing: border-box;
}

@media screen and (min-width: 767px) {

.ResourceList_filtersForm__SUACK {
        background: none;
        overflow: visible;
        padding: 0;
        padding-bottom: 60px
}
    }

.ResourceList_listHeader__Wy9Ks {
    grid-row: 2;
    grid-column: 1;
}

@media screen and (min-width: 768px) {

.ResourceList_listHeader__Wy9Ks {
        grid-column: 2
}
    }
.ResourceList_list__Kb4z_ {
    grid-row: 3;
    grid-column: 1;
}
@media screen and (min-width: 768px) {
.ResourceList_list__Kb4z_ {
        grid-column: 2
}
    }
.ResourceList_list__Kb4z_ {
    flex-grow: 1;
    display: grid;
    align-items: start;
    align-self: start;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
}
@media screen and (max-width: 979px) {
.ResourceList_list__Kb4z_ {
        grid-template-columns: 1fr;
        margin: 0
}
    }
.ResourceList_list__Kb4z_ .ResourceList_article__N1HrK {
        margin: 0 10px 20px;
    }
@media screen and (max-width: 979px) {
.ResourceList_list__Kb4z_ .ResourceList_article__N1HrK {
            margin: 0 0 20px
    }
        }
.ResourceList_viewType--list__Do5bd {
    grid-template-columns: 1fr;
}
.ResourceList_pagination__09nWM {
    grid-row: 4;
    grid-column: 1;
}
@media screen and (min-width: 768px) {
.ResourceList_pagination__09nWM {
        grid-column: 2;
}
    }
.ResourceList_pagination__09nWM {
    margin-bottom: 40px;
}
.ResourceList_noResults__UV1PX {
    grid-column: 1 / -1;
}
@media screen and (min-width: 768px) {
.ResourceList_main__lkaIC.ResourceList_hideAside__6Ffqy .ResourceList_listHeader__Wy9Ks {
            grid-column: 1 / span 2
    }
        }
@media screen and (min-width: 768px) {
.ResourceList_main__lkaIC.ResourceList_hideAside__6Ffqy .ResourceList_list__Kb4z_ {
            grid-column: 1 / span 2
    }
        }
@media screen and (min-width: 768px) {
.ResourceList_main__lkaIC.ResourceList_hideAside__6Ffqy .ResourceList_pagination__09nWM {
            grid-column: 1 / span 2
    }
        }

.TopBar_bar__JhRi1 {
    background: #fbfbfb;
    position: relative;
    width: 100%;
    z-index: 1060;
}
.TopBar_in__tb_5m {
    max-width: 1180px;
    margin: 0 auto;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (min-width: 768px) {
.TopBar_in__tb_5m {
        justify-content: space-between
}
    }
.TopBar_headline__raFOw {
    color: #000;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.16em;
    text-decoration: none;
}
@media screen and (min-width: 768px) {
.TopBar_headline__raFOw {
        font-size: 16px
}
    }

