.input {
    background: #fff;
    border: 1px solid #bcbcbc;
    border-radius: 30px 0 0 30px;
    border-right: 0;
    padding: 10px 20px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-size: 16px;
    &::placeholder {
        color: #8a9198;
        font-size: 14px;
    }
    &:focus-visible {
        outline: none;
    }
    &:focus {
        outline: none;
    }
}
.button {
    background: #fff;
    border: 1px solid #bcbcbc;
    border-left: 0;
    white-space: nowrap;
    border-radius: 0 30px 30px 0;
    padding: 10px;
    cursor: pointer;
    margin: 0;
    svg {
        stroke: #bcbcbc;
        stroke-width: 2px;
        width: 15px;
        height: 15px;
        transition: stroke 0.2s ease-in-out;
    }
    &:hover {
        svg {
            stroke: #000;
        }
    }
}
.form-group {
    display: grid;
    grid-template-columns: 1fr 60px;
    grid-template-rows: 1fr;
    position: relative;
    max-width: 495px;
    height: 60px;
    margin: 0 auto;
    &:focus-within > div input,
    &:focus-visible > div input,
    &:focus-within > button {
        border-color: #00baff;
        @supports (--css: variables) {
            border-color: var(--primary-brand-color-400);
        }
    }
}

.menu {
    position: absolute;
    margin: 0;
    top: 100%;
    left: 50%;
    padding: 0;
    transform: translate(-50%, 7px);
    width: 100%;
    max-width: 433px;
    text-align: left;
    cursor: default;
    border: 1px solid #00baff;
    border-radius: 7px;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
    max-height: 254px;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    font-weight: 300;
    font-size: 17px;
    line-height: 2;
    z-index: 1;
    @supports (--css: variables) {
        border-color: var(--primary-brand-color-400);
    }
    li {
        position: relative;
        padding: 10px 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: var(--roboto);
        font-weight: 400;
        font-size: 12px;
        color: #7d8f9f;
        cursor: pointer;
        em {
            font-weight: 700;
            font-style: normal;
        }
        &:hover {
            background: #fafafa;
            color: #000;
        }
    }
}
