/*------------------------------------*\
    ACADEMY BANNER
\*------------------------------------*/

.certification {
    .outer {
        position: relative;
        padding: 0;
        @media only screen and (min-width: 600px) {
            padding: 60px;
        }
        svg {
            position: absolute;
            filter: drop-shadow(8px 8px 41px rgba(0, 0, 0, 0.3));
            &:nth-of-type(1) {
                display: none;
                @media only screen and (min-width: 600px) {
                    display: block;
                    top: 7%;
                    left: 3%;
                    width: 92px;
                    height: 92px;
                }
                @media only screen and (min-width: 980px) {
                    top: 11%;
                    left: 10%;
                }
            }
            &:nth-of-type(2) {
                display: none;
                @media only screen and (min-width: 980px) {
                    display: block;
                    width: 72px;
                    height: 72px;
                    top: 41%;
                    left: 3%;
                }
            }
            &:nth-of-type(3) {
                display: none;
                @media only screen and (min-width: 600px) {
                    display: block;
                    bottom: 10%;
                    left: 17%;
                    width: 52px;
                    height: 52px;
                }
                @media only screen and (min-width: 980px) {
                    top: 59%;
                    left: 17%;
                }
            }
            &:nth-of-type(4) {
                display: none;
                @media only screen and (min-width: 600px) {
                    display: block;
                    bottom: 40%;
                    right: 28%;
                    width: 42px;
                    height: 42px;
                }
                @media only screen and (min-width: 980px) {
                    top: 27%;
                    right: 13%;
                }
            }
            &:nth-of-type(5) {
                display: none;
                @media only screen and (min-width: 600px) {
                    display: block;
                    top: 10%;
                    right: 17%;
                    width: 52px;
                    height: 52px;
                }
                @media only screen and (min-width: 980px) {
                    width: 72px;
                    height: 72px;
                    top: 69%;
                    right: 21%;
                }
            }
            &:nth-of-type(6) {
                display: none;
                @media only screen and (min-width: 600px) {
                    display: block;
                    width: 72px;
                    height: 72px;
                    top: 62%;
                    right: 2%;
                }
                @media only screen and (min-width: 980px) {
                    width: 92px;
                    height: 92px;
                    top: 46%;
                    right: 2%;
                }
            }
        }
    }
    .inner {
        padding: 20px;
        border-radius: 12px;
        border: 2px solid #d3d5d9;
        background-image: url('./assets/gradient.avif');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-origin: padding-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        justify-items: center;
        @media only screen and (min-width: 600px) {
            padding: 40px 20px;
        }
        @media only screen and (min-width: 980px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr auto;
        }
        h5 {
            margin: 0 0 23px;
            padding: 0;
            font-family: var(--rubik);
            font-weight: 600;
            font-size: 34px;
            line-height: 1.3;
            text-align: center;
            span {
                color: var(--primary-brand-color-600);
            }
        }
        .button {
            white-space: nowrap;
        }
    }
}
