.manager {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    font-family: 'RobotoFlex', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: $color-nav;
    @media screen and (max-width: 979px) {
        top: 88px;
    }
}
.filters {
    display: flex;
    gap: 20px;
    flex-direction: column;
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
}
.filtersLabel {
    flex-shrink: 0;
    margin-top: 0.98em;
    &:only-child {
        margin-top: 0;
    }
}
.view {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
    margin-left: auto;
    text-align: right;
    margin-top: 10px;
    margin-bottom: 10px;
}

.viewType {
    display: flex;
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;
    flex-direction: row;
    @media screen and (max-width: 979px) {
        display: none;
    }
    li {
        display: flex;
        align-items: center;
        padding: 0 5px;
        box-sizing: border-box;

        button {
            background: none;
            cursor: pointer;
            border: none;
            padding: 0;
            height: 15px;
            display: block;

            svg {
                fill: #dddfe2;
                transition: fill 0.2s ease-in-out;
                pointer-events: none;
            }
        }
        &.active,
        &:hover {
            svg {
                fill: #9ca7b1;
            }
        }
    }
}
.select {
    border: none;
    cursor: pointer;
    --arrow-color: var(--primary-brand-color-400);
    color: var(--primary-brand-color-400);
    padding: 5px 25px 5px 10px;
    text-align: right;
    box-sizing: border-box;
    appearance: none;
    background-color: #fff;
    background-image: linear-gradient(45deg, transparent 50%, var(--arrow-color) 50%),
        linear-gradient(135deg, var(--arrow-color) 50%, transparent 50%);
    background-position: calc(100% - 13px) calc(0.7em + 1px), calc(100% - 9px) calc(0.7em + 1px);
    background-size: 4px 4px, 4px 4px;
    background-repeat: no-repeat;

    &:focus:not(:focus-visible) {
        outline: none;
    }
}

.bubble {
    color: var(--primary-brand-color-400);
    border: 2px solid var(--primary-brand-color-400);
    border-radius: 4px;
    padding: 0.6em;
    margin: 0.4em 0.2em;
    display: inline-flex;
    align-items: center;
    gap: 7px;
    font-size: 14px;
    line-height: 0;
    font-weight: 400;

    button {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
    }

    img {
        display: block;
        width: 12px;
        height: 12px;
    }
}
