.news {
    --roboto: 'RobotoFlex', sans-serif;
    padding: 60px 0 30px;
    .inner {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        @media only screen and (max-width: 1279px) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
        }
        @media only screen and (max-width: 979px) {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
        }
        span {
            font-family: var(--roboto);
            font-weight: 400;
            font-size: 18px;
            line-height: 1.2;
            letter-spacing: 0.5px;
            display: block;
            margin-bottom: 1.25em;
            strong {
                font-weight: 500;
            }
        }
        ul {
            padding: 0;
            list-style: none;
            li {
                font-family: var(--roboto);
                font-weight: 500;
                font-size: 14px;
                color: #09222b;
                line-height: 1.5;
                margin-bottom: 25px;
                @media only screen and (max-width: 1279px) {
                    margin-bottom: 15px;
                }
                a {
                    color: #09222b;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .articles {
            padding: 0 70px 0 15px;
            @media only screen and (max-width: 1279px) {
                margin-top: 20px;
                padding: 0 10px;
                &:first-child {
                    margin-top: 0;
                }
            }
            @media only screen and (max-width: 979px) {
                padding: 0;
            }
        }
        .promo {
            padding: 0 15px;
            text-align: center;
            @media only screen and (max-width: 1279px) {
                margin-top: 20px;
                padding: 0 10px;
                &:first-child {
                    margin-top: 0;
                }
            }
            @media only screen and (max-width: 979px) {
                padding: 0;
            }
            figure {
                img {
                    box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.05);
                }
            }
            @media only screen and (max-width: 1279px) {
                grid-column: 1;
                grid-row: 1 / span 2;
                align-self: center;
            }
            @media only screen and (max-width: 979px) {
                grid-row: auto;
            }
        }
    }
}
