.checkbox-wrapprer {
    margin-bottom: 14px;
}
.label {
    display: grid;
    grid-template-columns: 15px 1fr;
    grid-template-rows: auto;
}

.label > span {
    padding-inline-start: 8px;
    font-family: 'RobotoFlex', 'Helvetica', 'Arial', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #576a7b;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.checkbox {
    background-color: #fff;
    position: relative;
    border-color: #fff;
    margin: 0;
    height: 13px;
    width: 13px;
    box-sizing: border-box;
    border-radius: 100px;
}

.checkbox:focus-visible {
    outline-offset: 3px;
}

.checkbox::after {
    visibility: visible;
    content: '';
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #e5e5e5;
    position: absolute;
    transition: border-color 0.6s ease-out;
    border-radius: 2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    margin: -2px;
}

.checkbox:checked::after {
    background-color: var(--primary-brand-color-400);
    background-image: url('../../../public/global/checkbox/check.svg');
    background-repeat: no-repeat;
    background-size: 7px 5px;
    background-position: 50%;
    border-color: var(--primary-brand-color-400);
}

.checkbox:checked ~ span {
    color: #1f262f;
}
