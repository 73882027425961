.bar {
    background: #fbfbfb;
    position: relative;
    width: 100%;
    z-index: 1060;
}
.in {
    max-width: 1180px;
    margin: 0 auto;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 768px) {
        justify-content: space-between;
    }
}
.headline {
    color: #000;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.16em;
    text-decoration: none;
    @media screen and (min-width: 768px) {
        font-size: 16px;
    }
}
