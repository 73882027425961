.newsletter {
    --roboto: 'RobotoFlex', sans-serif;
    --blue: #00baff;
    --placeholder: #8a9198;
    --form-error-text-color: #e22000;
    --form-valid-text-color: #47d800;
    background: #f1f1f1;
    padding: 40px 0;
    font-family: var(--roboto);
    position: relative;
    z-index: 1;
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 150px;
    }
    .thankYou {
        display: flex;
        justify-content: center;
        padding: 37px 0;
        svg {
            flex: 0 0 auto;
            display: inline-block;
            stroke: #00cb6b;
            width: 30px;
            height: 30px;
            margin-right: 15px;
            @media screen and (min-width: 980px) {
                flex-shrink: 1;
            }
        }
        h2 {
            margin: 0;
            padding: 0;
            font-weight: 400;
            font-size: 32px;
            line-height: 1.2;
            letter-spacing: 0.015em;
            strong {
                font-weight: 700;
            }
        }
    }
    .form {
        > span {
            font-size: 26px;
            line-height: 1.2;
            letter-spacing: 0.5px;
            text-align: center;
            display: block;
            margin-bottom: 1.25em;
            strong {
                font-weight: 500;
            }
        }
        .mainErrors {
            color: var(--form-error-text-color);
            text-align: center;
            margin-bottom: 50px;
        }
        form {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    .formGroup {
        position: relative;
        font-size: 14px;
        margin: 0 10px 56px;
        width: 280px;
        input[type='text'],
        input[type='email'] {
            background: #fff;
            border: 1px solid #e2e2e2;
            padding: 0.9em;
            border-radius: 5px;
            width: 100%;
            line-height: 1.8;
            box-sizing: border-box;
            &:focus:not(:focus-visible) {
                outline: none;
            }
            &:focus {
                border-color: var(--primary-brand-color-400);
            }
            &::placeholder {
                color: var(--placeholder);
            }
        }
        label {
            position: absolute;
            top: 0;
            left: 0.8em;
            pointer-events: none;
            padding: 0.9em 0;
            box-sizing: border-box;
            transition: transform 0.1s linear;
            line-height: 1;
            font-size: 18px;
            color: #677689;
            letter-spacing: 0.01em;
        }
        :focus + label,
        .notEmpty + label {
            transform: translateY(-1.7em) scale(0.83);
            transform-origin: top left;
            border-radius: 5px;
            color: #000;
        }
        .formControl {
            max-width: none;
            font-size: 18px;
            height: 2.8em;
            padding: 0.9em 0.8em;
        }

        &.hasError {
            input[type='text'],
            input[type='email'] {
                border: 1px solid var(--form-error-text-color);
                &:focus,
                &.notEmpty,
                &[value]:not([value='']):not(.empty) {
                    & ~ label {
                        color: var(--form-error-text-color);
                    }
                }
            }
            .checkbox input ~ span {
                color: var(--form-error-text-color);
            }
        }
        &.hasSuccess {
            input[type='text'],
            input[type='email'] {
                border: 1px solid var(--form-valid-text-color);
                &:focus,
                &.notEmpty,
                &[value]:not([value='']):not(.empty) {
                    & ~ label {
                        color: var(--form-valid-text-color);
                    }
                }
            }
            .checkbox input ~ span {
                color: var(--form-valid-text-color);
            }
        }
        .error {
            color: var(--form-error-text-color);
            font-size: 12px;
            letter-spacing: 0.05em;
            line-height: 1.4;
            position: absolute;
            bottom: -20px;
        }
    }
    .formGroupCheckbox {
        order: 1;
        width: 100%;
        margin-bottom: 27px;
        padding: 0 20px;
        .checkbox {
            max-width: 527px;
            margin: 0 auto;
            input[type='checkbox'] {
                visibility: hidden;
                position: absolute;
            }
            input[type='checkbox'] + .customCheckbox {
                display: block;
                position: absolute;
                width: 17px;
                height: 17px;
                border: 1px solid #e2e2e2;
                border-radius: 5px;
                box-sizing: border-box;
                top: 0;
                transform: translateX(-100%) translateX(-10px);
                background: #fff;
            }
            input[type='checkbox']:checked + .customCheckbox {
                background: #fff url('../../../../public/pages/resources/global/check-blue.svg') no-repeat center;
                background-size: 11px auto;
            }
            label {
                position: relative;
                cursor: pointer;
                display: flex;
                align-items: center;
                top: 0;
                left: 0;
                pointer-events: all;
                padding: 0;
                p {
                    font-size: 11px;
                    line-height: 1.3;
                    color: #000;
                    margin-top: 0;
                    margin-bottom: 0.6em;
                    letter-spacing: 0;
                    a {
                        color: var(--primary-brand-color-800);
                    }
                }
            }
        }
    }
    .buttonGroup {
        order: 1;
        width: 205px;
        margin-bottom: 28px;
        .btn {
            cursor: pointer;
            margin: auto;
            width: 100%;
            padding: 14px 30px;
            border-radius: 50px;
            border: 0;
            box-sizing: border-box;
            font-weight: 400;
            font-size: 16px;
            font-family: var(--roboto);
            letter-spacing: 1px;
            color: #fff;
            border-color: var(--primary-btn-bg-color);
            background: var(--primary-btn-bg-color);
            transition: background 0.2s ease-in-out;
            &:focus:not(:focus-visible) {
                outline: none;
            }
            &:hover,
            &:focus {
                border-color: var(--primary-btn-hover-bg-color);
                background: var(--primary-btn-hover-bg-color);
                &:focus:not(:focus-visible) {
                    outline: none;
                }
            }
            &:disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
    .terms {
        width: 100%;
        text-align: center;
        padding-top: 6px;
        order: 2;
        small {
            font-size: 10px;
            color: #6e6e6e;
            a {
                color: #6e6e6e;
                text-decoration: underline;
            }
        }
    }
}
