.section {
    background: linear-gradient(180deg, #f1f1f1 0, #fff 250px);
    padding-top: 34px;
}
.main {
    display: grid;
    gap: 20px;
    grid-template-rows: auto auto 1fr auto;
    grid-template-columns: 1fr;
    @media screen and (min-width: 768px) {
        grid-template-columns: 260px auto;
    }
    flex-direction: row-reverse;
}
.header {
    grid-row: 1;
    grid-column: 1 / -1;
    margin-bottom: 25px;
    @media screen and (min-width: 768px) {
        margin-bottom: 65px;
    }
    h1 {
        text-align: center;
        font-weight: 300;
        letter-spacing: -0.5px;
        margin-bottom: 20px;
        font-size: 26px;
        line-height: 1.2;
        @media screen and (min-width: 980px) {
            font-size: 32px;
        }
        strong {
            font-weight: 400;
        }
    }
}
.filters {
    grid-row: 2 / span 3;
    grid-column: 1;
    top: 88px;
    left: 0;
    align-self: start;
    z-index: 1070;
    position: fixed;
    height: calc(100vh - 88px);
    width: calc(100vw - 70px);
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    @media screen and (min-width: 767px) {
        display: block;
        position: sticky;
        padding: 0;
        padding-bottom: 60px;
        margin-top: -20px;
        height: auto;
        width: auto;
        transform: none;
        z-index: 1;
    }
}

.mobileFiltersShow {
    position: absolute;
    left: 100%;
    top: 49px;
    background: #fff;
    width: 45px;
    height: 68px;
    background-color: #fff;
    border-radius: 0 2px 2px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-left: 1px solid #e2e2e2;
    box-shadow: 1px 1px 5px 0 rgb(0 0 0 / 7%);
    @media screen and (min-width: 767px) {
        display: none;
    }

    img {
        height: 9px;
        width: 15px;
        transform: rotate(-90deg);
        transition: transform 0.2s ease-out 0.2s;
    }
}
.mobileFiltersHide {
    content: '';
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
    opacity: 0;
    @media screen and (min-width: 767px) {
        display: none;
    }
}

.filtersVisible {
    transform: none;

    .mobileFiltersHide {
        opacity: 1;
        transition: opacity 0.3s ease 0.3s;
    }
    .mobileFiltersShow img {
        transform: rotate(90deg);
    }
}

.filtersForm {
    background: #fff;
    height: 100%;
    width: 100%;
    overflow: auto;
    overscroll-behavior-y: contain;
    padding: 10px 20px;
    box-sizing: border-box;
    @media screen and (min-width: 767px) {
        background: none;
        overflow: visible;
        padding: 0;
        padding-bottom: 60px;
    }
}

.listHeader {
    grid-row: 2;
    grid-column: 1;
    @media screen and (min-width: 768px) {
        grid-column: 2;
    }
}
.list {
    grid-row: 3;
    grid-column: 1;
    @media screen and (min-width: 768px) {
        grid-column: 2;
    }
    flex-grow: 1;
    display: grid;
    align-items: start;
    align-self: start;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    @media screen and (max-width: 979px) {
        grid-template-columns: 1fr;
        margin: 0;
    }
    .article {
        margin: 0 10px 20px;
        @media screen and (max-width: 979px) {
            margin: 0 0 20px;
        }
    }
}
.viewType--list {
    grid-template-columns: 1fr;
}
.pagination {
    grid-row: 4;
    grid-column: 1;
    @media screen and (min-width: 768px) {
        grid-column: 2;
    }
    margin-bottom: 40px;
}
.noResults {
    grid-column: 1 / -1;
}
.main.hideAside {
    .listHeader {
        @media screen and (min-width: 768px) {
            grid-column: 1 / span 2;
        }
    }
    .list {
        @media screen and (min-width: 768px) {
            grid-column: 1 / span 2;
        }
    }
    .pagination {
        @media screen and (min-width: 768px) {
            grid-column: 1 / span 2;
        }
    }
}
