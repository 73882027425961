.noResults {
    text-align: center;
    padding-bottom: 40px;
    h2 {
        font-weight: 500;
        font-size: 28px;
        color: #1f262f;
        margin-top: 1.3em;
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 1.4;
        font-style: italic;
        color: #6f6f6f;
    }
}
.monster {
    width: 270px;
    height: 190px;
    margin-top: 40px;
}
