:global(.theme-high-contrast) .article {
    --green: #008547;
    --green-hover: #1a915a;
    --list-description: #6e757c;
}

.article {
    --blue: #00baff;
    --blue-hover: #00a2ff;
    --green: #00cd6e;
    --green-hover: #1ad27d;
    --list-description: #8a9198;
    --roboto: 'RobotoFlex', sans-serif;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.05);

    h2 {
        font-family: var(--roboto);
        letter-spacing: 0;
        font-weight: 700;
        font-size: 18px;
        color: #000;
        margin-top: auto;
        margin-bottom: auto;

        a {
            color: #000;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        mark {
            background-color: #ffea00;
        }
    }

    figure {
        margin: 0;
        img {
            display: block;
            max-width: 123px;

            .resources-list & {
                height: 182px;
                width: 123px;
            }
        }

        > a {
            display: block;
            text-align: center;
            text-decoration: none;

            .inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            h3 {
                font-family: var(--roboto);
                font-weight: 400;
                color: #fff;
                font-size: 40px;
                margin: -20px 0 0;
            }

            p {
                font-family: var(--roboto);
                font-weight: 500;
                font-size: 13px;
                color: #fff;
                margin: 0;
            }

            svg {
                width: 35px;
                height: 35px;
                fill: #fff;
                stroke: #fff;
            }

            &.green {
                background: var(--green);
            }

            &.blue {
                background: var(--blue);
            }
        }
    }

    &.videos,
    &.collections,
    &.webinars {
        figure a > * {
            width: 123px;
            height: 182px;
            position: relative;
        }
    }

    &.videos,
    &.webinars {
        figure a > .placeholder::after {
            content: '';
            width: 0;
            height: 0;
            border-top: 13px solid transparent;
            border-bottom: 13px solid transparent;
            border-left: 19px solid #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
        figure a > * {
            background: var(--primary-brand-color-400);
        }

        figure:hover a > * {
            background: var(--primary-brand-color-300);
        }
    }

    &.collections {
        figure a > * {
            background: var(--green);
        }

        figure:hover a > * {
            background: var(--green-hover);
        }

        .count {
            color: #fff;
            font-size: 18px;
            margin-bottom: 0.2em;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 10px 20px;
        @media screen and (max-width: 979px) {
            padding: 20px;
            justify-content: center;
        }

        h3 {
            font-family: var(--roboto);
            font-weight: 700;
            font-size: 18px;
            color: #000;
            margin-top: 10px;
            margin-bottom: 10px;
            @media screen and (max-width: 1279px) {
                font-size: 16px;
            }
            @media screen and (max-width: 979px) {
                margin-top: 0;
            }

            a {
                color: #000;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        p {
            font-family: var(--roboto);
            font-weight: 400;
            font-size: 12px;
            color: var(--list-description);

            [data-view='box'] & {
                display: none;
            }
            @media screen and (max-width: 979px) {
                display: none;
            }
        }

        ul {
            padding: 0;
            list-style: none;
            li {
                font-family: var(--roboto);
                font-weight: 500;
                color: var(--blue);

                &:first-child {
                    margin-left: 0;
                }
            }
            a {
                text-decoration: none;
                color: var(--primary-link-color);
                &:focus:not(:focus-visible) {
                    outline: none;
                }

                &:hover {
                    text-decoration: none;
                    color: var(--primary-link-hover-color);
                }
            }
        }

        .category {
            @media screen and (max-width: 979px) {
                display: none;
            }

            ul {
                display: flex;

                li {
                    padding: 0 15px;
                    font-size: 10px;
                    border-left: 1px solid #e8e8e8;

                    &:first-child {
                        border: 0;
                        padding-left: 0;
                    }
                }
            }
        }

        .details {
            display: flex;
            flex-direction: row;
            align-items: center;

            ul {
                text-align: center;

                li {
                    display: inline-block;
                    padding: 8px 30px;
                    border-left: 1px solid #e8e8e8;
                    font-size: 16px;
                    color: #5f5f5f;
                    @media screen and (max-width: 1279px) {
                        font-size: 14px;
                    }

                    &:first-child {
                        border: 0;
                    }
                }
            }

            .btn-wrap {
                flex-grow: 0;

                .btn {
                    font-weight: 400;
                    font-size: 16px;
                    padding: 16px 35px;
                    @media screen and (max-width: 1279px) {
                        display: none;
                    }
                }
            }
        }
        mark {
            background-color: #ffea00;
        }

        & > :last-child {
            margin-bottom: 5px;
            margin-top: auto;
        }
    }
}
