.fieldset {
    border: none;
    padding: 0;
    margin: 0;
}

.fieldset legend {
    font-family: 'RobotoFlex', 'Helvetica', 'Arial', sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #000;
    cursor: pointer;
    padding: 14px 40px 14px 0;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.fieldset legend:hover {
    cursor: pointer;
}

.fieldset legend::before {
    background: url('../../../public/global/multipleCheckbox/arrow.svg') no-repeat center center;
    content: '';
    display: inline-block;
    padding: 0;
    width: 14px;
    height: 6px;
    stroke-width: 1px;
    position: absolute;
    top: 50%;
    right: 13px;
    transform: translateY(-50%) rotate(0deg);
}
.fieldset .open ~ div {
    margin-bottom: 14px;
}
.fieldset .open::before {
    transform: translateY(-50%) rotate(180deg);
}
