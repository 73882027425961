.btn {
    --arrow-height: calc(1em * 0.8);
    --arrow-width: calc(1em * 15 / 13 * 0.8);
    font-family: var(--rubik);
    display: inline-block;
    box-sizing: border-box;
    border: 2px solid #e3d500;
    border-radius: 16px;
    padding: 14px 20px;
    line-height: 1;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    background: #fbe30c;
    color: #191b19;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    cursor: pointer;
    &.arrow > span {
        padding-right: calc(0.3em + var(--arrow-width));
    }
    &:focus:not(:focus-visible) {
        outline: none;
    }
    &.arrow::after {
        content: '';
        display: inline-block;
        width: var(--arrow-width);
        height: var(--arrow-height);
        margin-left: calc(-1 * var(--arrow-width));
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.581" viewBox="0 0 24 20.581"><path id="arrow-right" d="M23.5,75.426A1.717,1.717,0,0,0,23.5,73l-8.572-8.572A1.716,1.716,0,0,0,12.5,66.854l3.106,3.1L18.151,72.5H1.714a1.714,1.714,0,0,0,0,3.429H18.146L12.5,81.576A1.716,1.716,0,0,0,14.931,84L23.5,75.431Z" transform="translate(0 -63.925)" fill="%23191b19"/></svg>');
        vertical-align: middle;
        transform: translateY(-10%);
    }
    &:hover,
    &:active,
    &:focus {
        background-color: #38a2ff;
        border-color: #2c85d5;
        color: #fff;
        &.arrow::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.581" viewBox="0 0 24 20.581"><path id="arrow-right" d="M23.5,75.426A1.717,1.717,0,0,0,23.5,73l-8.572-8.572A1.716,1.716,0,0,0,12.5,66.854l3.106,3.1L18.151,72.5H1.714a1.714,1.714,0,0,0,0,3.429H18.146L12.5,81.576A1.716,1.716,0,0,0,14.931,84L23.5,75.431Z" transform="translate(0 -63.925)" fill="%23ffffff"/></svg>');
        }
    }
}
.large {
    padding: 17px 31px;
    border-radius: 24px;
    font-size: 18px;
    border-width: 3px;
}

.small {
    padding: 12px 14px;
    font-size: 14px;
    border-radius: 12px;
    border-width: 2px;
}

.black {
    background-color: #000;
    color: #fff;
    border-color: #000;
    &.arrow::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }
    &:hover,
    &:active,
    &:focus {
        background-color: #38a2ff;
        border-color: #2c85d5;
        color: #fff;
    }
}

.orange {
    background: #fd733c;
    border-color: #e2612b;
    color: #fff;
    &.arrow::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }
    &:hover {
        border-color: #de4d14;
        background-color: #ff5b1a;
    }
}
.max-orange {
    background: var(--primary-max-brand-color-300, #fc5b1a);
    border-color: var(--primary-max-brand-color-400, #de4d14);
    color: #fff;

    &.arrow::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }
    &:hover,
    &:active,
    &:focus {
        border-color: var(--primary-max-brand-color-400, #de4d14);
        background-color: var(--primary-max-brand-color-400, #de4d14);
    }
}

.max-black {
    background-color: #000;
    color: #fff;
    border-color: #000;
    &.arrow::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }
    &:hover,
    &:active,
    &:focus {
        border-color: var(--primary-max-brand-color-400, #de4d14);
        background-color: var(--primary-max-brand-color-400, #de4d14);
        color: #fff;
    }
}

.black-white {
    background-color: #000;
    color: #fff;
    border-color: #000;
    &.arrow {
        text-wrap: nowrap;
        &::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
        }
    }
    &:hover,
    &:active,
    &:focus {
        border-color: #000;
        background-color: #fff;
        color: #000;
        &.arrow::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23000"/></svg>');
        }
    }
}

.blue {
    background-color: #38a2ff;
    color: #fff;
    border-color: #3393f2;
    &.arrow::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }
    &:hover,
    &:active,
    &:focus {
        background-color: #38a2ff;
        border-color: #2c85d5;
        color: #fff;
    }
}

.blue-yellow {
    background-color: #38a2ff;
    color: #fff;
    border-color: #3393f2;
    &.arrow::after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }
    &:hover,
    &:active,
    &:focus {
        background-color: #fbe30c;
        border-color: #e3d500;
        color: #191b19;
        &.arrow::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23191b19"/></svg>');
        }
    }
}

.ghost {
    border-color: #abbcc9;
    background: transparent;
    color: #000;
    &:hover,
    &:active,
    &:focus {
        background-color: #000;
        border-color: #000;
        color: #fff;
    }
}

.ghost-blue {
    border-color: #19a1ff;
    background: transparent;
    color: #fff;
    transition: all 0.3s ease-in-out, border-color 0.3s ease-in-out;
    &.arrow::after {
        transition: all 0.3s ease-in-out, border-color 0.3s ease-in-out;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fff"/></svg>');
    }
    &:hover,
    &:active,
    &:focus {
        background-color: transparent;
        border-color: var(--primary-max-brand-color-300, #fc5b1a);
        color: var(--primary-max-brand-color-300, #fc5b1a);
        &.arrow::after {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15.787" height="13.537" viewBox="0 0 15.787 13.537"><path id="arrow-right" d="M15.456,71.49a1.129,1.129,0,0,0,0-1.6L9.818,64.255a1.129,1.129,0,1,0-1.6,1.6l3.718,3.714H1.128a1.128,1.128,0,0,0,0,2.255H11.936L8.225,75.536a1.129,1.129,0,0,0,1.6,1.6l5.638-5.638Z" transform="translate(0 -63.925)" fill="%23fc5b1a"/></svg>');
        }
    }
}
.icon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.3em;
    width: 1em;
    height: 1em;
    background: currentColor;
    mask-image: var(--icon-src);
    mask-size: contain;
    mask-repeat: no-repeat;
}
