.pagination {
    container-name: pagination;
    container-type: inline-size;
    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        list-style: none;
        padding: 0;
        justify-content: space-between;
        @container (min-width: 680px) {
            justify-content: flex-start;
        }
    }
    li {
        margin: 0 3px;
    }
}
.element {
    > * {
        text-decoration: none;
        color: inherit;
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        min-width: 32px;
        height: 32px;
        padding: 0 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    }
    > a:hover,
    > a:active {
        border-color: #bbb;
    }
    &.dots > * {
        border: 0;
        padding-left: 2px;
        padding-right: 2px;
        min-width: 32px;
        font-size: 19px;
        font-weight: 400;
    }
    &.current > * {
        color: var(--primary-link-color);
        border-color: currentColor;
    }
    &.prev > * {
        margin-right: 10px;
    }
    &.next > * {
        margin-left: 10px;
    }
    &.prev > *::before,
    &.next > *::before {
        content: '';
        display: inline-block;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
    }
    &.prev > *::before {
        border-right: 5px solid #576a7b;
    }
    &.next > *::before {
        border-left: 5px solid #576a7b;
    }
    &.isDisabled > * {
        pointer-events: none;
        opacity: 0.5;
    }
}

/* If pagination has "..." */
.pagination:has(.dots) {
    /* This selector SHOULD be sufficient but due to a bug in production build process (minification?) I had to wrap `:nth-last-child(2)` with `:is(:nth-last-child(2))` */
    /* :where(.element:not(.prev):not(.next):not(.current):not(.dots):not(:nth-child(2)):not(:nth-last-child(2)):not(.current + *):not(:has(+ .current))) { */
    :where(.element:not(.prev):not(.next):not(.current):not(.dots):not(:nth-child(2)):not(:is(:nth-last-child(2))):not(.current + *):not(:has(+ .current))) {
        display: none;
    }
}

/* Show all links BEFORE and one after selected one if only "end" dots shown  */
.pagination:has(.dots:nth-last-child(3)):not(:has(.dots:nth-child(3))) {
    :where(.element:has(~ .current)) {
        display: initial;
    }
    :where(.current + .element) {
        display: initial;
    }
}

/* Show all links AFTER and one before selected one if only "end" dots shown  */
.pagination:has(.dots:nth-child(3)):not(:has(.dots:nth-last-child(3))) {
    :where(.current ~ .element) {
        display: initial;
    }
    :where(.element:has(+ .current)) {
        display: initial;
    }
}

.pagination .element {
    @container (min-width: 680px) {
        display: initial;
    }
}
